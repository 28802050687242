<template>
  <div class="loginBox">

    <el-card class="login"  >


      <div slot="header" class="clearfix">
        <img :src="logo" style="width: 35%; height: 35%">
        <h2>海南省律师协会律师考核辅助系统</h2>
      </div>

      <div >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          align="center"
          label-width="70px"
          label-position="left"
          class="demo-ruleForm">
          <el-form-item label="用户名" prop="username">
            <el-input v-model.trim="ruleForm.username"></el-input>
          </el-form-item>

          <el-form-item label="密码" prop="password">
            <el-input
              show-password
              type="password"
              v-model.trim="ruleForm.password"
              auto-complete="off">
            </el-input>
          </el-form-item>

<!--          <el-form-item label="验证码" prop="code">-->
<!--            <el-row>-->
<!--              <el-col :span="12">-->
<!--                  <el-input v-model="ruleForm.code"></el-input>-->
<!--              </el-col>-->

<!--              <el-col :span="12">-->
<!--                <div>-->
<!--&lt;!&ndash;                  <img  :src=codeUrl  @click="codeUrl=codeUrl +'?'">&ndash;&gt;-->
<!--&lt;!&ndash;                  <img src="http://localhost:8083/login/code" onclick="this.src=this.src+'?'">&ndash;&gt;-->
<!--                </div>-->
<!--              </el-col>-->
<!--            </el-row>-->


<!--          </el-form-item>-->
          <el-form-item label="身份" prop="role">
            <el-radio v-model="ruleForm.role" label="4">律协</el-radio>
            <el-radio v-model="ruleForm.role" label="3">律所</el-radio>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">登录</el-button>
            <el-button @click="resetForm('ruleForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>

    </el-card>

  </div>
</template>

<script>
import md5 from 'js-md5'
import logo from "@/assets/logo.png"
import { request } from '@/network/network'

export default {
  data() {
    return {
      logo,
      code:'',
      // codeUrl: "http://localhost:8083/login/code",
      codeUrl: "http://43.139.249.33:8083/login/code",
      ruleForm: {
        username: '',
        password: '',
        code: "9527",
        role: ''
      },
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        role: [{ required: true, message: '请选择身份', trigger: 'blur' }]
      },
    }
  },

  methods: {
    //重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //提交表单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          let form = {"username":this.ruleForm.username,
            "password":md5(md5(this.ruleForm.password) + this.ruleForm.code.toLocaleLowerCase()),
            "code":this.ruleForm.code,"role":this.ruleForm.role,}
          // this.getEncryption();

          return request({
            url: '/login/login',
            withCredentials: true,
            method: 'post',
            data: form
          })
            .then((res) => {
              if (res.code == 0) {
                sessionStorage.setItem('isLogin', true)
                if (this.ruleForm.role == 4) {
                  sessionStorage.setItem('isSuper', true)
                } else {
                  sessionStorage.setItem('isSuper', false)
                };
                if(this.ruleForm.username == 'admin'){
                  sessionStorage.setItem('isX', "supersuper")
                }
                sessionStorage.setItem('information', JSON.stringify(res.data))

                this.$router.push('/home').then(res=>{
                  const loading = this.$loading({
                    lock: true,
                    text: '请稍等',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                  });
                  setTimeout(() => {
                    this.$router.go(0)
                    setTimeout(() => {
                      loading.close();
                    }, 1000)

                  }, 1000)
                })


                  // setTimeout(() => {
                  //   this.$router.go(0)
                  // }, 1000))

              } else {
                this.codeUrl = this.codeUrl +'?'
                this.$message({
                  message: res.msg+",请重新登录!",
                  type: "error",
                  duration: 2000,
                });
                this.$router.push('/login')
              }
            })
            .catch((err) => {
              this.codeUrl = this.codeUrl +'?'
              this.$message({
                message: "发生错误，请重新登录！",
                type: "error",
                duration: 2000,
              });
              this.$router.push('/login')
            })
        } else {
          this.$message({
            message: "请完善登录信息!",
            type: "error",
            duration: 2000,
          });
          console.log('error submit!!');
          return false;
        }
      });


    },
    getCode() {
      return request({
        url: '/login/code',
        method: 'get',
        withCredentials: true,
        params:{}
      })
        .then((res) => {
          this.code
          console.log(res)

        })
    },


    getEncryption(pss) {

      this.$set(this.ruleForm,"password", md5(md5(this.ruleForm.password) + this.ruleForm.code.toLocaleLowerCase()))
    }
  },

  created() {
    sessionStorage.clear()
    // this.getCode()
  }
}
</script>

<style lang="scss">
.login {
  border-radius: 15px;
  background-clip: padding-box;
  margin: 90px auto;
  width: 600px;
  padding: 35px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  box-shadow: 0 0 25px #cac6c6;
}

html {
  height: 100%;
  background-color: #ebeef5 ;
}

.loginBox {



  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  text-align: center;
  color: #505458;
}
</style>
